import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
 

let indexBannerSwiperTime = 5000
var indexBannerSwiper = new Swiper('.index-banner .swiper-container', {
    loop: true,
    effect: 'fade',
    autoplay: {
        delay: indexBannerSwiperTime,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        waitForTransition: false,
    },
    pagination: {
        el: '.index-banner .swiper-pagination',
        clickable: true
    },
    breakpoints: {
      768: {
        pagination: {
          el: '.index-banner .swiper-pagination',
          clickable: true
        },
      },
      1024: {
        pagination: {
          el: '.index-banner .swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"><svg><circle cx="20" cy="20" r="18"></circle></svg></div>';
          },
        },
      },
    },
    on: {
      transitionStart: function () {
        $('.index-banner .swiper-pagination .swiper-pagination-bullet').removeClass('restart').removeClass('restart2');
      }
    },
    
});
indexBannerSwiper.el.onmouseover = function () { //鼠标放上暂停轮播
  indexBannerSwiper.autoplay.stop();
}
indexBannerSwiper.el.onmouseleave = function () {
    indexBannerSwiper.autoplay.start();
    if ($('.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active').hasClass('restart')){
      $('.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active').removeClass('restart').addClass('restart2')
    } else if ($('.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active').hasClass('restart2')){
      $('.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active').removeClass('restart2').addClass('restart')
    }else{
      $('.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active').addClass('restart')
    } 
}

var indexProjectsSwiper = new Swiper('.index-projects-swiper .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
        nextEl: '.index-projects-swiper .swiper-button-next',
        prevEl: '.index-projects-swiper .swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    }
});